import axios from 'axios'
import jwtToken from 'shared-module/token/jwtToken'
import switches from 'shared-module/config/switches'
import appConfig from 'shared-module/config/appConfig'
import { UNAUTHORIZED } from 'shared-module/api/httpStatusCode'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'ecommerce-module/core/eCommerce.selectors'
import jwtDecode from 'jwt-decode'

const restapi = axios.create()
let isRefreshing = false
let failedQueue = []

// Helper to handle failed request queue
const processQueue = (error, token = null) => {
  failedQueue.forEach(prom => (error ? prom.reject(error) : prom.resolve(token)))
  failedQueue = []
}

// Helper to logout user and clear token
const disconnectSession = async () => {
  window.location = '/'
  await jwtToken.remove()
}

// Set up request interceptor
restapi.interceptors.request.use(
  config => {
    config.baseURL = window.location.origin.replace('webapp', 'restapi')
    config.headers.post['Content-Type'] = 'application/json'
    const token = jwtToken.get()
    if (token) config.headers.Authorization = `bearer ${token}`
    return config
  },
  error => Promise.reject(error),
)

// Handle token refresh and queue requests during refresh
const handleTokenRefresh = async (originalRequest, baseURL, refresh, deviceID, commerceRefreshToken, eCommerceEnabled) => {
  try {
    // Refresh WebApp Token
    if (refresh && !originalRequest.response.data?.type) {
      await axios.post(`${baseURL}/api/v3/user/refreshtoken`, { deviceID, refresh }).then(async response => {
        await jwtToken.set(response.data.token)
        await jwtToken.setRefresh(response.data.refresh)
        processQueue(null, { token: jwtToken.get(), sfccToken: null })

        if (originalRequest.url === '/api/v3/user/undefined') {
          const newToken = jwtDecode(jwtToken.get()).user._id
          originalRequest.url = `/api/v3/user/${newToken}`
        }
      })
    }

    // Refresh E-Commerce Token
    if (eCommerceEnabled) {
      const url = commerceRefreshToken ? '/api/v3/commerce/user/token/refresh' : '/api/v3/commerce/user/token'
      const data = commerceRefreshToken ? { commerce_refresh_token: commerceRefreshToken } : {}
      const headers = { Authorization: `bearer ${jwtToken.get()}` }

      await axios.post(`${baseURL}${url}`, data, { headers }).then(async response => {
        await localStorage.setItem(eCommerceTokenID, response.data.access_token)
        await localStorage.setItem(eCommerceRefreshTokenID, response.data.refresh_token)
        processQueue(null, { token: jwtToken.get(), sfccToken: response.data.access_token })

        if (originalRequest.url === '/api/v3/user/undefined') {
          const newToken = jwtDecode(jwtToken.get()).user._id
          originalRequest.url = `/api/v3/user/${newToken}`
        }
      })
    }

    isRefreshing = false
    return axios(originalRequest)
  } catch (error) {
    isRefreshing = false
    await disconnectSession()
    throw error
  }
}

// Set up response interceptor
restapi.interceptors.response.use(
  response => response,
  async error => {
    const originalRequest = error.config
    const baseURL = window.location.origin.replace('webapp', 'restapi')
    const eCommerceEnabled = appConfig.eCommerce.switch === switches.on
    const refresh = jwtToken.getRefresh()
    const deviceID = jwtToken.getDevice()
    const commerceRefreshToken = localStorage.getItem(eCommerceRefreshTokenID)

    if (error.response.status === UNAUTHORIZED && !originalRequest._retry) {
      originalRequest._retry = true

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject })
        })
          .then(token => {
            originalRequest.headers.Authorization = `bearer ${token.token}`
            originalRequest.headers['x-commerce-token'] = token.sfccToken
            return axios(originalRequest)
          })
          .catch(err => Promise.reject(err))
      }

      isRefreshing = true
      return handleTokenRefresh(originalRequest, baseURL, refresh, deviceID, commerceRefreshToken, eCommerceEnabled)
    }

    return Promise.reject(error)
  },
)

export default restapi

import { Trans } from 'react-i18next'
import { FormControl, FormControlLabel, FormGroup, FormLabel } from '@material-ui/core'
import CCheckbox from 'storybook-component-module/src/components/buttons/CCheckbox'

const ProfileCheckbox = ({ form, formik, name, label, legendKey, disabled, setOpen }) => {
  return (
    <FormControl component="fieldset" disabled={disabled} data-testid={name}>
      <FormLabel component="legend">
        <Trans i18nKey={legendKey} />
      </FormLabel>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value="end"
          control={(
            <CCheckbox
              id={name}
              name={name}
              roundedCheckbox
              checked={formik.values[name]}
              {...formik.getFieldProps(name)}
              inputProps={{ name: name, form: form }}
              onChange={(event) => {
                if (name === 'marketingEmail') {
                  if (!event.target.checked) { setOpen(true) }
                  console.log(formik.values)
                  if (event.target.checked) {
                    formik.setFieldValue('marketingEmail', event.target.checked)
                  }
                }
              }}
            />
          )}
          label={label}
          labelPlacement="end"
        />
      </FormGroup>
    </FormControl>
  )
}

export default ProfileCheckbox

import eCommerceApi from 'ecommerce-module/api/eCommerceApi'
import { eCommerceRefreshTokenID, eCommerceTokenID } from 'ecommerce-module/core/eCommerce.selectors'

export const SET_PRODUCTS = 'SET_PRODUCTS'
const setProducts = products => ({ type: SET_PRODUCTS, products })

const authenticateUser = () => eCommerceApi
  .authenticate()
  .then(data => {
    localStorage.setItem(eCommerceTokenID, data.access_token)
    localStorage.setItem(eCommerceRefreshTokenID, data.refresh_token)
  })
  .catch(error => error)

const fetchProducts = () => dispatch => eCommerceApi.fetchProducts()
  .then(plans => dispatch(setProducts({ plans: plans })))
  .catch(error => error)

const createBaskets = () => eCommerceApi
  .createBasket()
  .then(data => data)
  .catch(error => error)

const eCommerceActions = { authenticateUser, fetchProducts, setProducts, createBaskets }
export default eCommerceActions

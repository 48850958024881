import restapi from 'shared-module/api/restapi'
import commerceSelector, { eCommerceTokenID } from 'ecommerce-module/core/eCommerce.selectors'
import eCommerceBuilder from 'ecommerce-module/api/eCommerce.builder'

const authenticate = () => restapi
  .post('/api/v3/commerce/user/token')
  .then(response => response.data)
  .catch(error => error)

const fetchPlans = () => restapi
  .get(
    `/api/v3/commerce/products?ids=${commerceSelector.salesForcePlansIds}`, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
  .then(response => response.data.data.map(plan => eCommerceBuilder.buildECommercePlans(plan)))
  .catch(error => error)

const fetchProducts = async (retries = 3) => {
  try {
    const response = await restapi.get(
      '/api/v3/commerce/products?ids=D06121,D06131,D06141,D06120,D06130,D06140', {
        headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
      },
    )
    return response.data.data.map(addon => eCommerceBuilder.buildECommercePlans(addon))
  } catch (error) {
    if (retries > 0) {
      console.warn(`Retrying fetchProducts... Attempts left: ${retries}`)
      return fetchProducts(retries - 1)
    } else {
      console.error('Failed to fetch products after multiple attempts:', error)
      return []
    }
  }
}

const createBasket = () => restapi
  .post(
    '/api/v3/commerce/baskets', {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
  .then(response => response.data.data.map(basket => eCommerceBuilder.buildBaskets(basket)))
  .catch(error => error)

const getBasket = (basketId) => {
  return restapi
    .get(`/api/v3/commerce/baskets/${basketId}`, {
      headers: { 'x-commerce-token': localStorage.getItem(eCommerceTokenID) },
    })
    .then(response => response.data)
    .catch(error => {
      console.error('Error fetching basket:', error)
      return error
    })
}

const addItemToBasket = (basketId, itemDataList) => {
  const token = localStorage.getItem(eCommerceTokenID)

  const requestBody = {
    item_list: itemDataList.map(item => ({
      itemId: item.itemId,
      productId: item.productId,
      quantity: item.quantity || 1,
    })),
  }

  return restapi
    .post(
      `/api/v3/commerce/baskets/${basketId}/items`,
      requestBody,
      {
        headers: { 'x-commerce-token': token },
      },
    )
    .then(response => response.data)
    .catch(error => {
      console.error('Error adding item to basket:', error)
      return error
    })
}

const updateBasketItem = (basketId, itemDataList) => {
  const token = localStorage.getItem(eCommerceTokenID)

  const requestBody = {
    item_list: itemDataList.map(item => ({
      itemId: item.itemId,
      product_id: item.productId,
      quantity: item.quantity,
    })),
  }

  return restapi
    .patch(
      `/api/v3/commerce/baskets/${basketId}/items`,
      requestBody,
      {
        headers: { 'x-commerce-token': token },
      },
    )
    .then(response => response.data)
    .catch(error => {
      console.error('Error updating item in basket:', error)
      return error
    })
}

const eCommerceApi = { authenticate, fetchPlans, fetchProducts, createBasket, getBasket, addItemToBasket, updateBasketItem }
export default eCommerceApi

import { useDispatch, useSelector } from 'react-redux'
import eCommerceActions from 'ecommerce-module/core/eCommerce.actions'

export const useECommerceProducts = () => useSelector(state => state.eCommerceProducts)

export const useECommerceProductsActions = () => {
  const dispatch = useDispatch()

  return {
    getProducts: () => dispatch(eCommerceActions.fetchProducts()),
  }
}
